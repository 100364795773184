import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';
import {
  Formik, Form
} from 'formik';
import FormikTextField from '../components/FormikTextField';
import styled from 'styled-components';
import { useHistory } from "react-router-dom";
import {
  setToken
} from '../redux/appSlice';
import { useCookies } from 'react-cookie';

const ErrorMessage = styled.div`
  color: red;
  font-weight: bold;
`;

function Auth() {
  const { webservices } = useSelector(state => state.appSlice);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [cookies, setCookie] = useCookies();

  const submit = async (values) => {
    if (!values.email || !values.password) {
      alert("Tous les champs sont obligatoires");
      return;
    }
    try {
      const data = await axios.post(webservices + "/users/login", values, { withCredentials: true, credentials: 'include' });
      setCookie("token", data.data.token)
      dispatch(setToken(data.data));
      history.push('/')
    } catch (error) {
      setError(true);
      console.log(error)
    }

  }

  return (
    <>
      <Formik
        initialValues={{ email: "", password: "" }}
        onSubmit={(values) => {
          submit(values)
        }}
      >
        <Form>
          {error && <ErrorMessage>
            Mauvais login / mot de passe
          </ErrorMessage>}
          <FormikTextField name="email" label="Email" />
          <FormikTextField type="password" name="password" label="Mot de passe" />

          <Button type="submit">Valider</Button>
        </Form>
      </Formik>
    </>)
}

export default Auth;
