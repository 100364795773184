import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getMonument, recordSession, saveMonument } from "../redux/appSlice";
import styled from "styled-components";
import { Form, Formik } from "formik";
import FormikTextField from "../components/FormikTextField";
import FormikSwitch from "../components/FormikSwitch";
import FormikSelect from "../components/FormikSelect";
import DebugFormik from "../components/DebugFormik";
import ImageCrop from "../components/ImageCrop";
import { Button } from "@material-ui/core";
import Loading from "../components/Loading";

function Edit() {
  let { monumentID, type } = useParams();
  const { monument } = useSelector((state) => state.appSlice);
  const [isDefinition, setIsDefinition] = useState(false);
  const [src, setSrc] = useState();
  const [mapSrc, setMapSrc] = useState();
  const [srcSS, setSrcSS] = useState();
  const [sound, setSound] = useState();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMonument(monumentID));
  }, [monumentID]);

  useEffect(() => {
    setIsDefinition(type !== "monument");
  }, monument);

  useEffect(() => {
    if (monument.parent_id) {
      dispatch(getMonument(monument.parent_id, undefined, true));
    }
  }, [monument.parent_id]);

  const setMapCroppedImage = async (imageUrl) => {
    setMapSrc(imageUrl);
  };

  const save = async (values) => {
    setLoading(true);
    if (mapSrc) values.map_0 = mapSrc;
    values.image_0 = src;
    values.image_ss = srcSS;
    values.audio = sound;
    if (values.monument_id === "new") {
      delete values.monument_id;
    }
    dispatch(saveMonument(values));
  };

  const selectSound = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setSound(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  return (
    <>
      {loading && <Loading />}
      {monument && monument.monument_id == monumentID && (
        <Formik
          onSubmit={(values) => save(values)}
          initialValues={{
            ...monument,
            list: monument.hasOwnProperty("isleaf") ? !monument.isleaf : false,
          }}
        >
          {({ values }) => (
            <Form>
              <DebugFormik />
              {!isDefinition && (
                <FormikSelect
                  label="Type"
                  name="type"
                  options={[
                    { value: 1, label: "Non classé" },
                    { value: 2, label: "Eglise" },
                    { value: 3, label: "Château" },
                    { value: 4, label: "Musée" },
                  ]}
                />
              )}
              <FormikTextField
                label="Nom (50 car)"
                name="name"
                inputProps={{ maxLength: 50 }}
              />
              <FormikTextField
                label="Description (400 car)"
                name="description"
                inputProps={{ maxLength: 400 }}
                rows={3}
                multiline
              />
              {!isDefinition && (
                <>
                  {/* <FormikSelect label={"Type"} name="list" options={typeValues} defaultValue="Séquence" /> */}
                  <FormikSwitch label="Liste" name="list" />
                  {/* <FormikSwitch label="Timer" name="timer" /> */}
                  <FormikSelect
                    label="Langue"
                    name="language"
                    options={[
                      { value: "FR", label: "FR" },
                      { value: "EN", label: "EN" },
                      { value: "IT", label: "IT" },
                      { value: "ES", label: "ES" },
                      { value: "CN", label: "CN" },
                    ]}
                  />
                  <FormikTextField
                    label="Position (50 car)"
                    name="position"
                    inputProps={{ maxLength: 50 }}
                  />
                  <FormikTextField label="Horaires" name="timeschedule" />
                  Image :<br />
                  <ImageCrop setCroppedImage={setSrc} />
                  <br />
                  {values.list && (
                    <>
                      Image d'accueil :<br />
                      <ImageCrop setCroppedImage={setSrcSS} />
                      <br />
                    </>
                  )}
                  Carte :<br />
                  <ImageCrop setCroppedImage={setMapCroppedImage} />
                </>
              )}
              Audio :<br />
              <input
                dir="auto"
                type="file"
                accept=".mp3"
                onChange={selectSound}
              ></input>
              <br />
              <br />
              <Button type="submit">Sauvegarder</Button>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
}

export default Edit;
